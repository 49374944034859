import moment from 'moment/moment'

export const START_OF_DAY: number = 75600000
export const END_OF_DAY: number = 75599000

export const dateFormatConvert = (date: number, dayOrHour: boolean): Date => {
  const getNowDay = moment().startOf('day').toDate().getTime()
  if (dayOrHour) {
    return new Date(getNowDay + date)
  }

  return new Date(date - getNowDay)
}

export const createDateAsUTC = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
}

export const convertDateToUTC = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
}
