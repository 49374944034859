import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../redux/actions/modal-actions'
import moment from 'moment'
import DateTimeInput from '../inputs/DateTimeInput'
import { fetchPost, processError } from '../../utils/fetch-utils'
import { throwSuccessMessage } from '../../utils/errors-utils'
import { RootState } from '../../redux/reducers/rootReducer'

const ModalGatewaySetReboot: React.FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: RootState) => state.modals)
  const { DelayedRebootTime } = useSelector((state: RootState) => state.status)
  const [inputState, setInputState] = useState({
    Time: DelayedRebootTime || moment().toDate().getTime(),
  })

  const fetchSetReboot = async () => {
    try {
      const res = await fetchPost('/api/Gateway/DelayedReboot', null, modalData, inputState)
      if (res.Status === 0) {
        throwSuccessMessage('Successfully')
      }
    } catch (e) {
      processError(e, dispatch)
    }
  }

  const fetchCancelReboot = async () => {
    try {
      const res = await fetchPost('/api/Gateway/CancelDelayedReboot', null, modalData)
      if (res.Status === 0) {
        throwSuccessMessage('Successfully')
      }
    } catch (e) {
      processError(e, dispatch)
    }
  }

  const handleCancelReboot = () => {
    fetchCancelReboot()
    dispatch(hideModal())
  }

  const handleYes = () => {
    fetchSetReboot()
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        <FormattedMessage id="gateway.modal.set-reboot" />
      </Modal.Header>
      <Modal.Body>
        <DateTimeInput state={inputState} setState={setInputState} name="Time" label="gateway.modal.choose-time" minDate={moment().startOf('day').toDate()} />
      </Modal.Body>
      <Modal.Footer>
        {DelayedRebootTime > 0 && (
          <Button onClick={handleCancelReboot}>
            <FormattedMessage id="cancel-planned-reboot" />
          </Button>
        )}
        <Button onClick={handleYes}>
          <FormattedMessage id="apply" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ModalGatewaySetReboot)
