import React, { memo } from 'react'
import { Form } from 'react-bootstrap'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import en from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import classNames from 'classnames'
import { convertDateToUTC, createDateAsUTC } from '../../utils/time-utils'

registerLocale('en', en)
registerLocale('ja', ja)
setDefaultLocale('utc')

interface IInputProps {
  state: any
  name: string
  label: string
  disabled?: boolean
  className?: string
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date
  selectsStart?: boolean
  selectsEnd?: boolean
  startDate?: any
  endDate?: any
  showDateNotTime?: boolean

  setState(newState: any): void
  zIndex?: any
}

const DateTimeInput: React.FC<IInputProps> = ({
  state,
  name,
  zIndex = 9000,
  setState,
  label,
  disabled,
  className,
  showTimeSelect,
  showTimeSelectOnly,
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  showDateNotTime = false,
}) => {
  const intl = useIntl()

  const onChange = (date: Date) => {
    setState({
      ...state,
      [name]: createDateAsUTC(new Date(date)).getTime(),
    })
  }

  let isInvalid = false

  try {
    yup.number().required().validateSync(state[name])
  } catch (e) {
    isInvalid = true
  }

  let selectedDate = convertDateToUTC(new Date(state[name]))

  return (
    <Form.Group style={{ zIndex: zIndex }} className={classNames('d-flex', 'flex-column', className)}>
      <Form.Label>
        <FormattedMessage id={label} tagName="span" />
        <span>:</span>
      </Form.Label>
      <div>
        <DatePicker
          locale={intl.locale}
          disabled={disabled}
          className={classNames('form-control', isInvalid && 'isInvalid')}
          selected={selectedDate}
          onChange={onChange}
          timeCaption="time"
          dateFormat={showTimeSelectOnly ? 'HH:mm:ss' : showDateNotTime ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss'}
          timeFormat="HH:mm:ss"
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeInput
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
          timeIntervals={5}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      {isInvalid ? (
        <div className="d-block invalid-feedback">
          <FormattedMessage id="field-validation-error" tagName="span" />
        </div>
      ) : null}
    </Form.Group>
  )
}

export default memo(DateTimeInput)
