import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { brokerProfit, buildInfoItems, buildSummaryDeals, infoDirectionDeals, infoMs, infoProfitDealsMt4, infoProfitDerection, infoTimeNotUtc } from '../../utils/info-item-utils'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { buildTableData, buildTableHeadersConfigured } from '../../utils/table-utils'
import { aggregationBar } from '../../const/TradingHistoryConst'
import { showModal } from '../../redux/actions/modal-actions'
import { ModalType } from '../modals/modals'
import { buildDealsUrl } from '../../redux/actions/deals-actions'
import { throwErrorMessage } from '../../utils/errors-utils'
import { Action } from '../../redux/actions/actions'

const FixReportsRightbar: React.FC<IRightbar> = ({
  data: {
    Gateway,
    Platform,
    position: { Id },
  },
}) => {
  const dispatch = useDispatch()
  const { gateway } = useSelector((reduxState: any) => reduxState.gateways)
  const [report, setReport] = useState<any>({})
  const [sortOptions, setSortOptions] = React.useState({
    field: '',
    by: 'none',
  })

  const findType = gateway.Platforms.find((item: any) => item.Name === Platform)

  useEffect(() => {
    const url = new URL('/api/deals/executionReport', document.location.origin)
    url.searchParams.set('gateway', Gateway)
    url.searchParams.set('platform', Platform)
    url.searchParams.set('id', Id)

    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setReport(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [Gateway, Id, Platform, dispatch])

  const table = (
    <AppTable
      tableHeader={buildTableHeadersConfigured(aggregationBar, sortOptions, setSortOptions, 'aggregationDeals')}
      tableData={buildTableData(
        report?.LpExecutions ?? [],
        aggregationBar,
        () => {},
        () => {},
      )}
      scrollOptions={{ x: 'scroll', y: 'visible-hidden' }}
    />
  )

  const directionRowOne = buildInfoItems(['ExecutionId', 'Account', 'Symbol', infoDirectionDeals('Side')], report, 'fixReports', 'default', true)
  const directionRowTwo = buildInfoItems(['PlatformName', 'ClientOrderId', infoTimeNotUtc('TransactionTime'), 'OrderStatus'], report, 'fixReports', 'default', true)
  const profitRowOne = buildInfoItems(
    [infoProfitDerection('TpBrokerProfit', !String(report?.TpBrokerProfit)?.includes('-')), brokerProfit('Profit')],
    report,
    'fixReports',
    'default',
    true,
  )
  const priceRowOne = buildInfoItems([infoProfitDealsMt4('PlatformRequestPrice', false, report.Side), 'LpRequestPrice', 'TpRequestPrice'], report, 'fixReports', 'default', true)
  const priceRowTwo = buildInfoItems([infoProfitDealsMt4('LastPrice', false, report.Side), 'LpExecutionPrice'], report, 'fixReports', 'default', true)
  const priceRowNine = buildInfoItems(['AvgPrice', 'StopPrices'], report, 'fixReports', 'default', true)
  const priceRowTree = buildInfoItems(['TpMarkup', 'TotalMarkup'], report, 'fixReports', 'default', true)
  const priceRowFour = buildInfoItems(['LpPriceSlippage', 'PlatformPriceSlippage'], report, 'fixReports', 'default', true)
  const volumeRowOne = buildInfoItems(['LastVolume', 'LotLastVolume', 'OrderVolume'], report, 'fixReports', 'default', true)
  const volumeRowTwo = buildInfoItems(['FilledVolume', 'RemainingVolume'], report, 'fixReports', 'default', true)
  const additionalRowOne = buildInfoItems([infoMs('LpExecutionTime'), infoMs('TpExecutionTime')], report, 'fixReports', 'default', true)
  const additionalRowTwo = buildInfoItems([infoMs('FullExecutionTime')], report, 'fixReports', 'default', true)
  const additionalRowSeven = buildInfoItems(['OrderId'], report, 'fixReports', 'default', true)
  const additionalRowEight = buildInfoItems(['ExecutionType', 'OrderType'], report, 'fixReports', 'default', true)
  const additionalRowTree = buildInfoItems(['UserGroup', 'MtOrderType', 'ContractSize', 'Digits'], report, 'fixReports', 'default', true)
  const additionalRowFour = buildInfoItems(['OrderRejectReason', 'Comment', 'Session'], report, 'fixReports', 'default', true)
  const additionalRowFive = buildInfoItems(['Placement'], report, 'fixReports', 'default', true)
  const additionalRowSix = buildInfoItems(['OriginalClientOrderId'], report, 'fixReports', 'default', true)
  const commission = buildInfoItems(['Commission'], report, 'fixReports', 'default', true)

  const additionalExecutionTimestampsOne = buildInfoItems(['PlatformRequestTimestamp', 'LpRequestTimestamp', 'LpExecutionTimestamp'], report, 'fixReports', 'additional', true)
  const additionalExecutionTimestampsTwo =
    findType?.Type === 'extapi' || findType?.Type === 'ctrader' || findType?.Type === 'match-trader'
      ? buildInfoItems(['LpAnswerTimestamp', 'TpExeucutionTime'], report, 'fixReports', 'additional', true)
      : buildInfoItems(['LpAnswerTimestamp', 'TpExeucutionTime', 'PlatformExecutionTimestamp'], report, 'fixReports', 'additional', true)

  const showModalDetails = () => {
    const url = buildDealsUrl({
      Gateway,
      Platform,
      Order: report.OrderId,
      data: report,
      PageName: 'bookSnapshotReport',
    })
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(data => {
        if (data.Errors?.length) {
          throwErrorMessage(data.Errors.join(' '), true)
          return
        } else {
          dispatch({ type: Action.GotReport, data })
          dispatch(
            showModal(ModalType.ModalDetails, {
              headerText: 'ModalDetails',
              params: {
                Gateway,
                Platform,
                Order: report.OrderId,
                data: report,
                PageName: 'reportDownload',
              },
            }),
          )
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }

  return (
    <>
      <Card>
        <Card.Header
          style={{
            color: 'rgb(141, 171, 196)',
            fontWeight: 500,
            fontSize: '13px',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="closed-order-detail" />
        </Card.Header>
        <Card.Body>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>General</span>
            <section className="row mt-2">
              <div className="col-6">{directionRowOne}</div>
              <div className="col-6">{directionRowTwo}</div>
            </section>
            <div className="dealsOrder mt-2"></div>
          </div>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Profit</span>
            <section className="row  mt-2">
              <div className="col-6">{profitRowOne}</div>
              <div className="col-6">
                <div className="row default info-item">
                  <div className="col-6" style={{ fontSize: 13, fontWeight: 400, color: '#7094B2' }}>
                    Total broker profit
                  </div>
                  <div className="col-6">
                    <div
                      className={String(report?.TotalBrokerProfit)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                    >{`${report['TotalBrokerProfit']} `}</div>
                    {String(report?.TotalBrokerProfit) === String(report?.TotalBrokerProfitUsd) ? null : (
                      <>
                        <span style={{ height: 21, color: '#30527A', fontSize: 16, fontWeight: 500 }}>{'|'}</span>
                        <div
                          className={String(report?.TotalBrokerProfitUsd)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                        >{` ${report['TotalBrokerProfitUsd']}`}</div>
                      </>
                    )}
                  </div>
                </div>
                {findType?.Type === 'fixapi' || findType?.Type === 'fixapioz' || findType?.Type === 'fixapipxm' ? commission : null}
              </div>
            </section>
            <div className="dealsOrder mt-2"></div>
            <div className="mb-2">
              <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Price</span>
              <section className="row  mt-2">
                <div className="col-6">
                  <span className="modalDetailsFix" onClick={showModalDetails}>
                    Details...
                  </span>
                  {priceRowOne}
                </div>
                <div className="col-6">{priceRowTwo}</div>
              </section>
              <section className="row mt-2">
                <div className="col-6">{priceRowNine}</div>
              </section>
              <div className="dealsOrder mt-2"></div>
            </div>
            <section className="row mb-2">
              <div className="col-6">{priceRowTree}</div>
              <div className="col-6">{priceRowFour}</div>
            </section>
            <div className="mb-2">
              <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Volume</span>
              <section className="row mt-2">
                <div className="col-6">{volumeRowOne}</div>
                <div className="col-6">{volumeRowTwo}</div>
              </section>
              <div className="dealsOrder mt-2"></div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <AppAccordion
        item={{
          title: <FormattedMessage id="additional" />,
          item: (
            <>
              <section className="row mb-2">
                <div className="col-6">{additionalExecutionTimestampsOne}</div>
                <div className="col-6">{additionalExecutionTimestampsTwo}</div>
                <div className="dealsOrder mt-2"></div>
              </section>

              <section className="row mb-2">
                <div className="col-6">{additionalRowOne}</div>
                <div className="col-6">{additionalRowTwo}</div>
                <div className="dealsOrder mt-2"></div>
              </section>

              <section className="row mb-2">
                <div className="col-6">{additionalRowSeven}</div>
                <div className="col-6">{additionalRowEight}</div>
                <div className="dealsOrder mt-2"></div>
              </section>

              <section className="row mb-2">
                <div className="col-6">{additionalRowTree}</div>
                <div className="col-6">{additionalRowFour}</div>
                <div className="dealsOrder mt-2"></div>
              </section>
              <section className="row">
                <div className="col-6">{additionalRowFive}</div>
                <div className="dealsOrder mt-2"></div>
              </section>
              <section className="row mt-2">
                <div className="col-6">{additionalRowSix}</div>
              </section>
            </>
          ),
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
      />

      {report.Summary?.length !== 0 && (
        <AppAccordion
          item={{
            title: <FormattedMessage id="mt5.volume-distribution" />,
            item: [
              <div className="mr-2 mt-2 mb-4">{buildSummaryDeals(report.Summary)}</div>,
              <div className="mt-2 mb-4">
                <span style={{ color: '#324766', fontWeight: 600 }} className="mb-4">
                  Details
                </span>
                <div style={{ marginTop: 16 }}> {table}</div>
              </div>,
            ],
          }}
          style={{ fontSize: '14px' }}
          isHidden={false}
          render={report?.Summary?.length}
        />
      )}
    </>
  )
}

export default FixReportsRightbar
